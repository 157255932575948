<template>
    <div class="searchCompany">
       <div class="top">
            <step :stepNum="stepNum"></step>
       </div>
       <div class="searchBox"><search></search></div>
       <div class="footer">
           <div>【匹配须知】：</div>
           <div>1.只需输入主体名称和基本信息，可实现快速匹配，匹配到与您的主体相关的政策</div>
           <div> 2.如欲查询精准的匹配政策，您需花3-5分钟按步骤完善主体信息，请认真填写</div>
           <div>3.原则上信息越完整，匹配政策越精准，但政策变动可能会导致匹配会有些许偏差</div>
       </div>
    </div>
</template>

<script>
import search from '../searchCompany/search.vue';
import step from '../components/index.vue';
export default {
  name: 'searchCompany',
  components: {
    search,
    step
  },
  data () {
    return {
      stepNum: 0
    };
  }
};
</script>

<style lang="less" scoped>
    .searchCompany{
        padding-bottom: 139px;
        .top{
            margin-top: 31px;
            width: 100%;
            // height: 411px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .searchBox{
            display: flex;
           flex-direction: column;
           align-items: center;
        //    background-color: rgba(235, 241, 249, 100);
           height: 330px;
           padding-top: 72px;
           background-image: url("~@/assets/pc/images/matching/search-banner.png");
        }
        .footer{
            padding-top: 17px;
            margin-top: 40px;
            width: 100%;
            height: 133px;
            background-color: rgba(241, 247, 253, 100);
            color: rgba(0, 0, 0, 0.4);
            font-size: 14px;
            text-align: left;
            font-family: SourceHanSansSC-regular;
            text-indent: 27px;
            >div{
                margin-bottom: 17px;
            }
        }
    }
</style>
