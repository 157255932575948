<template>
  <div class="search" style="margin-top:82px">
    <el-autocomplete
      id="auto"
      v-model="entityName"
      :fetch-suggestions="querySearch"
      placeholder="请输入您的主体名称"
      clearable
      @select="handleSelect"
      :trigger-on-focus="false"
    >
      <template slot-scope="{ item }">
        <span class="name">
            <span>{{item.value.split(entityName)[0]}}</span>
            <span style="color:red" v-if="item.value.indexOf(entityName) != -1">{{entityName}}</span>
            <span>{{item.value.split(entityName)[1]}}</span>
        </span>
        <span class="exist" v-if="item.hasHistory" style="float:right;color:rgba(0, 0, 0, 0.3)">匹配过的企业</span>
      </template>
      <i slot="prefix" class="el-input__icon el-icon-search" style="font-size:24px;"></i>
    </el-autocomplete>
    <div style="margin-top:20px;font-size:14px;display: flex;">
      <div style="18px">历史记录</div>
      <div>
        <div v-for="(item, index) in historyList" :key="index" @click="clickHistory(item.entityName)"
          style="margin-left:20px;margin-bottom:15px;cursor: pointer;"><i class="el-icon-time"
            style="margin-right:10px;color:rgba(153, 153, 153, 71);font-size:14px"></i>{{ item.entityName }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { request } from '@/network';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  name: 'search',
  data () {
    return {
      entityName: '',
      list: [],
      historyList: [],
      timeou: null
    };
  },
  created () {
    this.getQueryRecord();
  },
  methods: {
    querySearch (queryString, cb) {
      const dom = document.getElementById('auto');
      dom.blur(); // 先失焦再聚焦实现input可以展开select
      dom.focus();
      if (queryString) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          request({
            method: 'POST',
            url: '/pcp/projectmatch/allentityName',
            data: {
              word: queryString,
              pageNo: 1,
              pageSize: 10,
              memberId: getMemberId()
            }
          }).then(res => {
            if (res.data.code !== 200) return this.$message.error('没有找到您想要的内容');
            const result = [];
            const data = res.data.data;
            data.forEach(v => {
              result.push({
                value: v.entityName,
                hasHistory: v.hasHistory
              });
            });
            cb(result);
          });
        }, 1000);
      }
    },
    handleSelect (item) {
      localStorage.removeItem('localData');
      this.$router.push({
        path: 'infoCompany',
        query: {
          entityName: item.value,
          navigateTo: '2'
        }
      });
    },
    search () {
      localStorage.removeItem('localData');
      this.$router.push({
        path: 'infoCompany',
        query: {
          entityName: this.entityName,
          navigateTo: '2'
        }
      });
    },
    clickHistory (value) {
      localStorage.removeItem('localData');
      this.$router.push({
        path: 'infoCompany',
        query: {
          entityName: value,
          navigateTo: '2'
        }
      });
    },
    // 获取历史搜索记录
    async getQueryRecord () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/match/queryRecord',
        params: {
          id: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('历史记录出错了...');
      this.historyList = res.data;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  width: 653px;
  height: 67px;
  padding-left: 60px;
}

/deep/input::-webkit-input-placeholder {
  text-indent: 20px;
  font-size: 18px;
  color: rgba(153, 153, 153, 100);
  font-family: SourceHanSansSC-regular;
}

/deep/.el-input__icon{
  width: 44px;
}

</style>
